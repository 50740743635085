<div 
  class="editor"
  [class.d-flex]="true"
  [class.align-items-center]="!showCharCount" 
  [class.disabled]="disabled"
  [class.ng-touched]="ngTouched"
  [class.ng-valid]="ngValid"
  [class.ng-dirty]="ngDirty"
  [class.ng-invalid]="ngInvalid"
  [class.focused]="classFocused || ownedByEmojiSelector"
  [class.flex-column]="showCharCount"
  [style.min-height]="!showCountInline && showCharCount ? '141px' : undefined"
  [class.align-items-end]="showCharCount"
  (click)="editor.quillEditor.focus()"
>
  <quill-editor 
    #editor
    [modules]="getModuleConfig()" 
    (onEditorCreated)="onEditorInit($event)" 
    [placeholder]="placeholder"
    (onContentChanged)="onContentChanged($event)"
    [readOnly]="readOnly || disabled"
    [maxLength]="maxLength"
    class="d-block"
    [class.ng-touched]="ngTouched"
    [class.ng-valid]="ngValid"
    [class.ng-dirty]="ngDirty"
    [class.ng-invalid]="ngInvalid"
    [class.flex-grow-1]="showCharCount"
    (onFocus)="onEditorFocus()"
    (onBlur)="onEditorBlur()"
    [formats]="['format-placeholder']"
    [trimOnValidation]="false"
  >
    <div quill-editor-toolbar class="d-none">
      <span class="ql-formats">
        <button [title]="'Bold'" (click)="handleBoldButtonClick()" class="btn">
          <fa-icon [icon]="faBold" size="2xs"></fa-icon>
        </button>
        <button [title]="'Italic'" (click)="handleItalicButtonClick()" class="btn">
          <fa-icon [icon]="faItalic" size="2xs"></fa-icon>
        </button>
        <button [title]="'Italic'" (click)="handleStrikeButtonClick()" class="btn">
          <fa-icon [icon]="faStrikethrough" size="2xs"></fa-icon>
        </button>
      </span>
      <span class="ql-formats">
        <button [title]="'Bold'" (click)="handleHeaderButtonClick(3)" class="btn">
          <fa-icon [icon]="faHeading" size="2xs"></fa-icon><sub>1</sub>
        </button>
        <button [title]="'Bold'" (click)="handleHeaderButtonClick(2)" class="btn">
          <fa-icon [icon]="faHeading" size="2xs"></fa-icon><sub>2</sub>
        </button>
        <button [title]="'Bold'" (click)="handleHeaderButtonClick(1)" class="btn">
          <fa-icon [icon]="faHeading" size="2xs"></fa-icon><sub>3</sub>
        </button>
      </span>
    </div>
  </quill-editor>
  <div class="bottom-container w-100 d-flex justify-content-end" *ngIf="showCharCount">
    <div 
      [class]="'d-flex char-count text-sm ' + ((currentLength > maxLength) ? ' text-danger' : '')"
      [class.flex-grow-1]="true"
      [class.text-end]="true"
      [class.inline-count]="true"
      [class.justify-content-end]="true"
    >
      <div>
        {{maxLength - currentLength}}
      </div>
      <div class="text-end emoji-picker ms-2" (click)="$event.preventDefault();" *ngIf="allowUnicodeEmojis || allowCustomEmojis">
        <span appEmojiSelector [editorOwner]="this"><fa-icon [icon]="faFaceSmile" size="lg"></fa-icon></span>
      </div>
    </div>
    <div 
      class="d-flex emoji-picker me-2" 
      *ngIf="!showCharCount && (allowUnicodeEmojis || allowCustomEmojis)"
      (click)="$event.preventDefault();"
    >
      <span appEmojiSelector [editorOwner]="this"><fa-icon [icon]="faFaceSmile" size="lg"></fa-icon></span>
    </div>
  </div>
</div>
