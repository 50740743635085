import { CommonModule } from '@angular/common'
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import {
  faBold,
  faHeading,
  faItalic,
  faStrikethrough
} from '@fortawesome/free-solid-svg-icons'
import { ContentChange, QuillModule, QuillModules } from 'ngx-quill'
import { NgScrollbarModule } from 'ngx-scrollbar'
import Quill, { Delta } from 'quill/core'
import { Range } from 'quill/core/selection'
import { DashboardService } from 'src/app/core/services/dashboard.service'
import {
  FormatPlaceholderData,
  ReplaceablePlaceholder,
  ReplaceablePlaceholderType
} from 'src/app/quilljs/format-anchor'
import { parse } from '@twemoji/parser'
import {
  generateUUID,
  retrieveEmojiUnicodeByShortName,
  retrieveMatchingEmojis
} from 'src/app/core/utils/app-util'
import { EmojiSelectorDirective } from 'src/app/core/directive/emoji-selector.directive'
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons'

@Component({
  selector: 'app-editor',
  standalone: true,
  imports: [
    FontAwesomeModule,
    CommonModule,
    FormsModule,
    QuillModule,
    NgScrollbarModule,
    EmojiSelectorDirective
  ],
  templateUrl: './editor.component.html',
  styleUrl: './editor.component.scss'
})
export class EditorComponent
  implements AfterContentInit, OnChanges, OnInit, OnDestroy, OnChanges
{
  uuid = generateUUID()

  @Input()
  readOnly = false

  @Input()
  disabled = false

  @Input()
  value: string = ''

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>()

  @Input()
  maxLength = 2000

  currentLength = 0

  @Input()
  modules: QuillModules = {}

  @Input()
  showCharCount: boolean = true

  @Input()
  showCountInline: boolean = false

  @Input()
  blockEnterKey: boolean = false

  @Input()
  placeholder = 'Ingresa un texto...'

  @Input()
  availablePlaceholders: PlaceholderOption[] = []

  @Input()
  allowRoleMention: boolean = true

  @Input()
  allowUserMention: boolean = true

  @Input()
  allowChannelMention: boolean = true

  @Input()
  allowCustomEmojis: boolean = true

  @Input()
  allowUnicodeEmojis: boolean = true

  @Input()
  ngTouched = false

  @Input()
  ngValid = false

  @Input()
  ngDirty = false

  @Input()
  ngInvalid = false

  @HostBinding('class.focused')
  classFocused = false

  ownedByEmojiSelector = false

  editor?: Quill = undefined
  changedByAPI = false

  faBold = faBold
  faItalic = faItalic
  faStrikethrough = faStrikethrough
  faHeading = faHeading
  faFaceSmile = faFaceSmile

  modulesMention: QuillModules = {
    mention: {
      allowedChars: /^[A-Za-z0-9\s.+-_]*$/,
      mentionDenotationChars: ['{', '@', '#', ':'],
      positioningStrategy: 'fixed',
      dataAttributes: [
        'type',
        'value',
        'real_value',
        'text_color',
        'customEmojiId',
        'customEmojiAnimated',
        'suggestion_description'
      ],
      blotName: 'format-placeholder',
      renderItem: (
        item: FormatPlaceholderData,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        searchTerm: string
      ): string | HTMLElement => {
        const div = this.renderer.createElement('div') as HTMLDivElement

        if (item.type === 'dc-emoji') {
          div.classList.add('d-flex')
          div.innerHTML =
            '<span class="d-inline-block" style="width: 30px;"><span class="img d-inline-block" style="width: 22px; height: 22px;">' +
            twemoji.parse(item.real_value) +
            '</span></span>' +
            (item.suggestion_name ?? item.value)
        } else if (item.type === 'dc-custom-emoji') {
          div.classList.add('d-flex')
          const id = item.customEmojiId ?? ''
          const extension = item.customEmojiAnimated ? '.gif' : '.png'
          div.innerHTML =
            '<span class="d-inline-block" style="width: 30px;"><span class="img d-inline-block" style="width: 22px; height: 22px;"><img src="https://cdn.discordapp.com/emojis/' +
            id +
            extension +
            '" class="emoji" /></span></span>' +
            (item.suggestion_name ?? item.value)
        } else {
          div.innerText = item.suggestion_name ?? item.value
        }

        if (item.text_color !== undefined) {
          div.style.color = item.text_color
        }

        if (item.suggestion_description !== undefined) {
          const divDescription = this.renderer.createElement(
            'div'
          ) as HTMLDivElement
          divDescription.innerHTML = item.suggestion_description
          divDescription.classList.add('description')
          div.appendChild(divDescription)
        }
        return div
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      source: (searchTerm: string, renderList: any, mentionChar: string) => {
        const values: FormatPlaceholderData[] = []

        if (mentionChar === '{' && this.availablePlaceholders.length > 0) {
          for (const placeholder of this.availablePlaceholders) {
            values.push({
              value: placeholder.name,
              real_value: placeholder.name,
              type: 'placeholder',
              suggestion_description: placeholder.description
            })
          }

          if (searchTerm.length === 0) {
            renderList(values, searchTerm)
          } else {
            const matches = []
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i])
            renderList(matches, searchTerm)
          }
        } else if (mentionChar === '@' && this.allowRoleMention) {
          this.dashboard.getActiveServerRoles().forEach((r) => {
            values.push({
              value: (r.name.startsWith('@') ? '' : '@') + r.name,
              real_value: r.id,
              type: 'dc-role-mention',
              text_color: r.colorHex,
              suggestion_description: 'ID: ' + r.id
            })
          })

          if (searchTerm.length === 0) {
            renderList(values, searchTerm)
          } else {
            const matches = []
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i])
            renderList(matches, searchTerm)
          }
        } else if (mentionChar === '#' && this.allowChannelMention) {
          this.dashboard
            .getActiveServerChannels(['TEXT', 'NEWS', 'VOICE', 'FORUM'])
            .forEach((ch) => {
              values.push({
                value: '#' + ch.name,
                real_value: ch.id,
                type: 'dc-channel',
                suggestion_description: 'ID: ' + ch.id
              })
            })

          if (searchTerm.length === 0) {
            renderList(values, searchTerm)
          } else {
            const matches = []
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i])
            renderList(matches, searchTerm)
          }
        } else if (
          mentionChar === ':' &&
          (this.allowCustomEmojis || this.allowUnicodeEmojis)
        ) {
          if (this.allowCustomEmojis) {
            // Emojis de DC que coincidan con este
            for (const guildEmoji of this.dashboard.getActiveServerEmojis()) {
              if (guildEmoji.name.includes(searchTerm)) {
                values.push({
                  value: guildEmoji.name,
                  real_value:
                    '<' +
                    (guildEmoji.isAnimated ? 'a' : '') +
                    ':' +
                    guildEmoji.name +
                    ':' +
                    guildEmoji.id +
                    '>',
                  type: 'dc-custom-emoji',
                  suggestion_name: ':' + guildEmoji.name + ':',
                  customEmojiId: guildEmoji.id,
                  customEmojiAnimated: guildEmoji.isAnimated
                })
              }
            }
          }

          if (this.allowUnicodeEmojis) {
            const results = retrieveMatchingEmojis(searchTerm)
            if (results.length === 0) {
              renderList(values, searchTerm)
            } else {
              for (const res of results) {
                values.push({
                  value: res.unicode,
                  real_value: res.unicode,
                  type: 'dc-emoji',
                  suggestion_name: res.name
                })
              }
              renderList(values, searchTerm)
            }
          } else {
            renderList(values, searchTerm)
          }
        }
      }
    }
  }

  replaceablePlaceholders: ReplaceablePlaceholder[] = [
    {
      type: 'placeholder',
      matchRegex: /{[a-zA-Z0-9_.]*}/,
      matchRegexGlobal: /{[a-zA-Z0-9_.]*}/g,
      acceptMatch: (match: string) => {
        return (
          this.availablePlaceholders.find((p) => p.name === match) !== undefined
        )
      },
      embedDeserialize: (match: string) => {
        return {
          type: 'placeholder',
          value: match,
          real_value: match,
          suggestion_description:
            this.availablePlaceholders.find((p) => p.name === match)
              ?.description ?? ''
        } as FormatPlaceholderData
      },
      embedSerialize: (data: FormatPlaceholderData) => {
        return data.real_value
      }
    },
    {
      type: 'dc-user-mention',
      matchRegex: /<@!*[0-9]+>/,
      matchRegexGlobal: /<@!*[0-9]+>/g,
      acceptMatch: () => {
        return true
      },
      embedDeserialize: (match: string) => {
        const extractIDRegex = /<@!*([0-9]+)>/
        const capturedGroup = match.match(extractIDRegex)![1]
        return {
          type: 'dc-user-mention',
          value: '<@' + capturedGroup + '>',
          real_value: capturedGroup
        } as FormatPlaceholderData
      },
      embedSerialize: (data: FormatPlaceholderData) => {
        return '<@' + data.real_value + '>'
      }
    },
    {
      type: 'dc-role-mention',
      matchRegex: /<@&[0-9]+>/,
      matchRegexGlobal: /<@&[0-9]+>/g,
      acceptMatch: () => {
        return true
      },
      embedDeserialize: (match: string) => {
        const extractIDRegex = /<@&([0-9]+)>/
        const capturedGroup = match.match(extractIDRegex)![1]

        // Intentar encontrar el nombre del rol...
        const role = this.dashboard
          .getActiveServerRoles()
          .find((r) => r.id === capturedGroup)

        let roleDisplay = ''
        if (role === undefined) {
          roleDisplay = '<@&' + capturedGroup + '>'
        } else {
          if (role.name.startsWith('@')) {
            roleDisplay = role.name
          } else {
            roleDisplay = '@' + role.name
          }
        }

        return {
          type: 'dc-role-mention',
          value: roleDisplay,
          real_value: capturedGroup,
          text_color: role?.colorHex
        } as FormatPlaceholderData
      },
      embedSerialize: (data: FormatPlaceholderData) => {
        return '<@&' + data.real_value + '>'
      }
    },
    {
      type: 'dc-channel',
      matchRegex: /<#[0-9]+>/,
      matchRegexGlobal: /<#[0-9]+>/g,
      acceptMatch: () => {
        return true
      },
      embedDeserialize: (match: string) => {
        const extractIDRegex = /<#([0-9]+)>/
        const capturedGroup = match.match(extractIDRegex)![1]

        // Intentar encontrar el nombre del canal...
        const channel = this.dashboard
          .getActiveServerChannels(['TEXT', 'NEWS', 'VOICE', 'FORUM'])
          .find((ch) => ch.id === capturedGroup)

        let channelDisplay = ''
        if (channel === undefined) {
          channelDisplay = '<#' + capturedGroup + '>'
        } else {
          channelDisplay = '#' + channel.name
        }

        return {
          type: 'dc-channel',
          value: channelDisplay,
          real_value: capturedGroup
        } as FormatPlaceholderData
      },
      embedSerialize: (data: FormatPlaceholderData) => {
        return '<#' + data.real_value + '>'
      }
    },
    {
      type: 'dc-emoji',
      matchRegex: /:[0-9a-zA-Z_+-]+:/,
      matchRegexGlobal: /:[0-9a-zA-Z_+-]+:/g,
      acceptMatch: (text) => {
        if (!this.allowUnicodeEmojis) return false
        return retrieveEmojiUnicodeByShortName(text) !== null
      },
      embedDeserialize: (match: string) => {
        const unicode = joypixels.shortnameToUnicode(match)
        return {
          type: 'dc-emoji',
          value: unicode,
          real_value: match
        } as FormatPlaceholderData
      },
      embedSerialize: (data: FormatPlaceholderData) => {
        return data.real_value
      }
    },
    {
      type: 'dc-custom-emoji',
      matchRegex: /<a?:[a-zA-Z0-9_]+:[0-9]+>/,
      matchRegexGlobal: /<a?:[a-zA-Z0-9_]+:[0-9]+>/g,
      acceptMatch: (text) => {
        if (!this.allowCustomEmojis) return false
        let matchId = text.split(':')[2]
        matchId = matchId.substring(0, matchId.length - 1)
        return (
          this.dashboard
            .getActiveServerEmojis()
            .find((e) => e.id === matchId) !== undefined
        )
      },
      embedDeserialize: (match: string) => {
        let matchId = match.split(':')[2]
        matchId = matchId.substring(0, matchId.length - 1)
        const animated = match.startsWith('<a')
        return {
          type: 'dc-custom-emoji',
          value: matchId,
          real_value: match,
          customEmojiAnimated: animated,
          customEmojiId: matchId
        } as FormatPlaceholderData
      },
      embedSerialize: (data: FormatPlaceholderData) => {
        return data.real_value
      }
    }
  ]

  dde = 0

  constructor(
    private dashboard: DashboardService,
    private renderer: Renderer2,
    private ref: ElementRef
  ) {}

  ngAfterContentInit(): void {
    this.valueChange.subscribe((val) => {
      this.currentLength = val.length
    })

    this.dashboard.getActiveServerChannels()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changed = changes['value']
    if (changed !== undefined && changed !== null && !changed.firstChange) {
      if (
        changed.previousValue !== changed.currentValue &&
        !this.changedByAPI
      ) {
        if (
          changed.currentValue !== undefined &&
          typeof changed.currentValue === 'string'
        ) {
          this.setContentFromValue()
        }
      }

      this.changedByAPI = false
    }
  }

  ngOnInit(): void {
    EditorComponent.instances.push(this)
  }

  ngOnDestroy(): void {
    EditorComponent.instances.filter((i) => i.uuid !== this.uuid)
  }

  getModuleConfig(): QuillModules {
    return { ...this.modules, ...this.modulesMention }
  }

  onEditorInit(event: Quill) {
    this.editor = event
    this.setContentFromValue()
  }

  setContentFromValue(valueToSet: string = this.value): void {
    // El delta es calculado posteriormente.
    this.value = valueToSet
    this.currentLength = this.value.length
    const delta = new Delta()
    delta.insert(valueToSet)
    this.editor?.setContents(delta)
  }

  resetEditorValue(): void {
    if (this.editor === undefined) return

    const delta = new Delta()
    delta.insert(this.value)

    this.editor.setContents(delta)
  }

  // {users} asd {user} asdasd {server}
  onContentChanged(event: ContentChange) {
    // Delta con el contenido original del editor previo a nuestros cálculos
    const deltaOriginal = event.editor.getContents()
    // Delta resultado en caso de que necesitemos aplicar cambios
    let deltaResult = new Delta()
    // Flag para determinar si el contenido tiene placeholders que requieren actualizar el contenido del editor
    let editorNeedsContentUpdate = false

    // Seleccion original del editor
    const selection = event.editor.getSelection()
    // Variable que nos ayudará a determinar en donde ubicar la selección en caso de cambiar el contenido del editor
    let selectionIndexToUse = selection?.index ?? 0
    // Variable que nos ayudará a determinar en donde ubicar el rango de la selección en caso de cambiar el contenido del editor
    let selectionLengthToUse = selection?.length ?? 0
    // Variable auxiliar que nosotros calculamos para determinar en que índice del contenido del editor nos estamos ubicando
    let currentEditorContentIndex = 0
    // Variable de cuanto offset vamos a agregar al resultado de la selección, solo en caso de que el usuario haya hecho un
    // CTRL + V (pegar) con placeholders.
    let selectionIndexOffsetToAdd = 0

    let allReplaceableHoldersSource = ''
    for (const replaceablePlaceholder of this.replaceablePlaceholders) {
      if (
        replaceablePlaceholder.type === 'dc-channel' &&
        !this.allowChannelMention
      )
        continue

      if (
        replaceablePlaceholder.type === 'dc-role-mention' &&
        !this.allowRoleMention
      )
        continue

      if (
        replaceablePlaceholder.type === 'dc-user-mention' &&
        !this.allowUserMention
      )
        continue

      allReplaceableHoldersSource +=
        '(?:' + replaceablePlaceholder.matchRegex.source + ')|'
    }
    allReplaceableHoldersSource = allReplaceableHoldersSource.substring(
      0,
      allReplaceableHoldersSource.length - 1
    )
    const allReplaceableHoldersSourceRegex = RegExp(
      allReplaceableHoldersSource,
      'g'
    )

    // Determinar si necesitamos agregar algun offset a la selección final por que el usuario hizo CTRL + V (pegar) con placeholders
    // Pero solo determinaremos si el rango de la selección es 0, es decir, el usuario pegó el contenido sin haber seleccionado un texto
    // ya que, si el usuario selecciona un texto se aplica otra lógica para que el texto pegado con placeholders tenga la longitud de selección
    // adecuada luego del pegado.
    if (selectionLengthToUse === 0) {
      // Verificar si el Delta que se va a realizar contiene tags de placeholders
      event.delta.ops.forEach((op) => {
        // Solo nos interesa las operaciones insert de tipo string
        if (typeof op.insert === 'string') {
          const text = op.insert
          // Verificar si el insert contiene tags de placeholders
          if (text.match(allReplaceableHoldersSourceRegex)) {
            // Hay que calcular cuanto de offset vamos a agregar para que la selección quede justo después de los placeholders pegados (luego de aplicar el formato)
            const matchedPlaceholders = text.matchAll(
              allReplaceableHoldersSourceRegex
            )

            // Primero, haremos que el offset sea igual a la longitud del texto insertado con placeholders
            selectionIndexOffsetToAdd = text.length

            for (const matchedPlaceholder of matchedPlaceholders) {
              const allCompatibleReplaceablePlaceholders =
                this.replaceablePlaceholders.filter((rp) =>
                  matchedPlaceholder[0].match(rp.matchRegex)
                )!
              let accepted = false
              // Solo considerar placeholders soportados, no los que no son válidos...
              for (const matcher of allCompatibleReplaceablePlaceholders) {
                if (matcher.acceptMatch(matchedPlaceholder[0])) {
                  accepted = true
                  break
                }
              }
              if (!accepted) continue

              // Luego, restamos la longitud de cada match de placeholder y hacemos que solo se considere 1 de longitud, ya que los Embed en QuillJS tienen una
              // longitud de 1.
              // ----------------------------------------------------------------
              // Por ejemplo si el usuario pega el texto:     {user} hola {server}
              // El texto pegado tiene una longitud de:       20
              // Pero... sabemos que hay 2 placeholders, {user} y {server}, por lo que
              // la longitud total del texto es:              8, ya que {user} y {server} los consideraremos como longitud 1 por ser un Embed
              selectionIndexOffsetToAdd -= matchedPlaceholder[0].length - 1
            }
          }
        }
      })
    }

    // Calculamos las operaciones del contenido actual del editor, y si es necesario formatear algun placeholder lo hacemos
    for (const op of deltaOriginal.ops) {
      if (typeof op.insert !== 'string') {
        // Si la operación no se trata de un insert de tipo string entonces no hacemos nada, hacemos que el Delta resultado
        // tenga la operación original sin alterar.
        deltaResult.insert(op.insert!)
        // Como esta operación se trata de un Embed podemos asumir que el index del contenido es +1, ya que un Embed tiene
        // una longitud de 1 espacio en el editor (de acuerdo a QuillJS).
        currentEditorContentIndex++
      } else {
        // Si la operación se trata de un insert de tipo string entonces tenemos más trabajo que hacer...
        // Primero, almacenamos el texto de la operación
        const operationText = op.insert

        // Verificamos si la operación tiene placeholders
        if (operationText.match(allReplaceableHoldersSourceRegex)) {
          // Hay un placeholder en esta operación, hora de complicar las cosas
          const matchedPlaceholders = operationText.matchAll(
            allReplaceableHoldersSourceRegex
          )

          // Variable auxiliar para ayudarnos a determinar desde donde comienza la parte previa
          // de texto desde el placeholder a formatear, por ejemplo:
          //
          // Suponiendo que la operación tiene el texto:      hola mundo {user}
          // nos interesa saber desde donde comienza "hola mundo ", con la ayuda de la variable indexPrevTextPart podemos
          // saber en donde comienza el texto previo, en este caso desde el indice 0, por lo que, el texto previo sería "hola mundo "
          let indexPrevTextPart = 0

          for (const matchedPlaceholder of matchedPlaceholders) {
            // Comenzamos a trabajar con los placeholders que hicieron match, pero solo trabajaremos con los placeholders que son válidos
            // si el usuario ingresa un placeholder que no existe no lo vamos a formatear...

            const allCompatibleReplaceablePlaceholders =
              this.replaceablePlaceholders.filter((rp) =>
                matchedPlaceholder[0].match(rp.matchRegex)
              )!
            let replaceablePlaceholder: ReplaceablePlaceholder | undefined =
              undefined
            // Solo considerar placeholders soportados, no los que no son válidos...
            for (const matcher of allCompatibleReplaceablePlaceholders) {
              if (
                matchedPlaceholder[0].match(matcher.matchRegex) &&
                matcher.acceptMatch(matchedPlaceholder[0])
              ) {
                replaceablePlaceholder = matcher
                break
              }
            }

            if (replaceablePlaceholder === undefined) {
              // El placeholder no existe, asi que bueno, no haremos mucho... al Delta resultado le vamos a dar de regreso el texto original
              // sin modificar...
              const matchIndex = matchedPlaceholder.index ?? 0
              const unmodifiedTextPart = operationText.substring(
                indexPrevTextPart,
                matchIndex + matchedPlaceholder[0].length
              )
              deltaResult.insert(unmodifiedTextPart)
              // Ajustar el indice del contenido del editor
              currentEditorContentIndex += unmodifiedTextPart.length
              // Posicionamos el índice de la variable auxiliar justo después de la ubicación en donde hizo match este placeholder, esto para que
              // el próximo placeholder sepa desde donde debe leer la cadena de texto para obtener el texto previo al placeholder.
              indexPrevTextPart = matchIndex + matchedPlaceholder[0].length
              continue
            }

            // Estamos trabajando con un placeholder válido, marcamos el flag de que el editor necesitará ser actualizado
            // ya que vamos a hacer cambios al contenido.
            editorNeedsContentUpdate = true

            // Indice donde el placeholder se encuentra dentro del string de la operación
            const matchIndex = matchedPlaceholder.index ?? 0
            // Obtenemos el texto previo al placeholder
            const prevTextPart = operationText.substring(
              indexPrevTextPart,
              matchIndex
            )
            // Una vez que obtuvimos el texto previo al placeholder, posicionamos el índice de la variable auxiliar justo después de la ubicación
            // en donde hizo match este placeholder
            indexPrevTextPart = matchIndex + matchedPlaceholder[0].length

            // Al Delta resultado le agregamos el texto previo al placeholder
            deltaResult.insert(prevTextPart)

            // Y sumamos la longitud del texto previo a la variable que mantiene el indice calculado de donde estamos trabajando en el contenido
            currentEditorContentIndex += prevTextPart.length

            // Si el indice en donde estamos trabajando en el contenido es menor al indice de la selección del usuario entonces hay que
            // ajustar el indice de selección del usuario, ya que el {placeholder} con el que estamos trabajando dejará de tener la longitud
            // que tenía y pasará a tener una longitud de 1, ya que el nuevo formato se trata de un Embed.
            if (currentEditorContentIndex < selectionIndexToUse) {
              // Necesitamos ajustar la posición de la selección del usuario, le quitamos la longitud del placeholder y hacemos que este placeholder
              // se considere con una longitud de 1, ya que pasará a ser un Embed.

              // Antes de hacer esto, primero tenemos que calcular en que index comienza el placeholder y en que index termina
              const placeholderIndexStart = matchIndex
              const placeholderIndexEnd =
                placeholderIndexStart + matchedPlaceholder[0].length

              if (placeholderIndexEnd < selectionIndexToUse) {
                // Si el index final del placeholder es menor a donde se encuentra el cursor del usuario, entonces podemos
                // quitar la longitud completa del placeholder del cursor del usuario para que se posicione correctamente
                // al final del placeholder.
                selectionIndexToUse -= matchedPlaceholder[0].length - 1
              } else {
                // En este caso, el cursor del usuario se encuentra en medio del placeholder, por ejemplo: {u|ser}
                // aqui tenemos que ajustar el cursor del usuario corrigiendo la posición en base a la distancia de letras
                // desde la derecha del placeholder en donde se encontraba el cursor del usuario
                selectionIndexToUse -=
                  selectionIndexToUse - placeholderIndexStart - 1
              }
            } else if (
              currentEditorContentIndex <
              selectionIndexToUse + selectionLengthToUse
            ) {
              // En este caso, el indice donde estamos trabajando en el contenido es menor al indice de la longitud del texto que el usuario seleccionó.
              // Por ejemplo:    Hola mundo cruel
              //                      xxxxx ---> "x" es el texto seleccionado
              //
              // Si el usuario tiene en su portapapeles el texto "hola {user}" y usa CTRL + V, el texto seleccionado se reemplaza.
              //
              // Sin la adecuación de longitud pasaría esto luego de pegar:
              //          Hola hola {user} cruel
              //               xxxxx   x  xxxxxx ---> Se seleccionaría la palaba "cruel" por que QuillJS considera {user} como una longitud de 6, pero ahora pasó a
              //                                      tener una longitud de 1 por que se trata de un Embed.
              //
              // Con la adecuación de longitud pasaría esto luego de pegar:
              //          Hola hola {user} cruel
              //               xxxxx   x     ---> No se seleccionaría la palaba "cruel" por que la adecuación le notificará a QuillJS que trate {user} con una longitud de 1.
              selectionLengthToUse -= matchedPlaceholder[0].length - 1
            }

            // Insertamos el embed del placeholder
            deltaResult.insert({
              'format-placeholder': replaceablePlaceholder.embedDeserialize(
                matchedPlaceholder[0]
              )
            })

            // Como vamos a insertar un embed, sumamos +1 al indice del contenido calculado
            currentEditorContentIndex++
          }

          // Si luego de formatear los placeholders hay texto residual después del placeholder... lo agregamos al Delta resultado...
          const postTextPart = operationText.substring(
            indexPrevTextPart,
            operationText.length
          )

          if (postTextPart.length > 0) {
            deltaResult.insert(postTextPart)
            currentEditorContentIndex += postTextPart.length
          }
        } else {
          // Eureka! La operación no tenia placeholders, podemos pasar la operación directamente al Delta resultado ya que no
          // tenemos que modificar nada...
          deltaResult.insert(operationText)
          // Sumamos al indice calculado del contenido del editor la longitud del texto de la operación, ya que no le hicimos nada
          // a esta operación...
          currentEditorContentIndex += operationText.length
        }
      }
    }

    if (this.blockEnterKey && event.source === 'user') {
      const deltaResultWithoutLineBreak = new Delta()
      let i = 0
      for (const op of deltaResult.ops) {
        if (typeof op.insert !== 'string') {
          deltaResultWithoutLineBreak.insert(op.insert!)
        } else {
          if (op.insert.includes('\n')) {
            const matchCount = op.insert.matchAll(/[\r\n]+/g)
            let matchCountNumber = 0
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            for (const match of matchCount) {
              matchCountNumber++
            }

            if (i + 1 === deltaResult.ops.length && matchCountNumber === 1) {
              // Es la línea final, QuillJS siempre agrega un \n al final...
              deltaResultWithoutLineBreak.insert(op.insert!)
            } else {
              editorNeedsContentUpdate = true
              let lineWithoutBreaks = op.insert!.replace(/[\r\n]+/g, '')
              if (i + 1 === deltaResult.ops.length) {
                lineWithoutBreaks += '\n'
              }
              deltaResultWithoutLineBreak.insert(lineWithoutBreaks)
            }
          } else {
            deltaResultWithoutLineBreak.insert(op.insert!)
          }
        }
        i++
      }

      if (editorNeedsContentUpdate) {
        deltaResult = deltaResultWithoutLineBreak
      }
    }

    // Twemoji Support
    if (this.allowUnicodeEmojis) {
      // Primero vamos a checar si hay coincidencias de emojis en el contenido...
      const deltaWithTwemoji = new Delta()
      let twemojiCodePointsFoundCount = 0
      let twemojiFound = false
      //console.log('TWEMOJI PARSED')
      for (const op of deltaResult.ops) {
        if (typeof op.insert !== 'string') {
          // No se trata de un string, asi que lo insertamos directamente
          deltaWithTwemoji.insert(op.insert!)
        } else {
          const parsedEmojis = parse(op.insert)
          if (parsedEmojis.length > 0) {
            editorNeedsContentUpdate = true
            // El string contiene emojis!!
            const originalInsert = op.insert
            //console.log('Original insert: ' + originalInsert)
            let indexOffset = 0
            for (const emoji of parsedEmojis) {
              twemojiCodePointsFoundCount += 1
              twemojiFound = true
              const indexStart = emoji.indices[0]
              const indexEnd = emoji.indices[1]
              //console.log('indexOffset: ' + indexOffset)
              //console.log(indexStart + ' - ' + indexEnd)

              if (indexStart != indexOffset) {
                const substring = originalInsert
                  .substring(indexOffset, indexStart)
                  .replace('\uFEFF', '')
                if (substring.length > 0) {
                  //console.log('Hay texto previo')
                  //console.log('Substring: ' + substring)
                  // Hay texto antes de este emoji
                  deltaWithTwemoji.insert(substring)
                }
              }

              indexOffset = indexEnd

              // Insertar emoji
              //console.log(emoji.text)
              deltaWithTwemoji.insert({
                'format-placeholder': {
                  type: 'dc-emoji',
                  value: emoji.text,
                  real_value: emoji.text
                } as FormatPlaceholderData
              })
            }

            if (indexOffset < originalInsert.length) {
              const substring = originalInsert
                .substring(indexOffset, originalInsert.length)
                .replace('\uFEFF', '')
              if (substring.length > 0) {
                //console.log('Hay texto posterior')
                //console.log('Substring: ' + substring)
                // Hay texto antes de este emoji
                deltaWithTwemoji.insert(substring)
              }
            }
          } else {
            // No tiene emojis, lo insertamos tal cual
            deltaWithTwemoji.insert(op.insert!.replace('\uFEFF', ''))
          }
        }
      }

      if (twemojiFound) {
        //console.log('TWEMOJI FOUND')
        deltaResult = deltaWithTwemoji

        // Ajustar el cursor
        if (this.editor !== undefined) {
          const selection = this.editor!.getSelection()
          //console.log(selection)
          if (selection != null) {
            //console.log(twemojiCodePointsFoundCount)
            selectionIndexToUse += twemojiCodePointsFoundCount
          }
        }
      }
    }

    if (editorNeedsContentUpdate) {
      event.editor.setContents(deltaResult, 'api')
      setTimeout(() => {
        if (event.editor.hasFocus()) {
          event.editor.setSelection(
            {
              index: selectionIndexToUse + selectionIndexOffsetToAdd,
              length: selectionLengthToUse
            },
            'user'
          )
        }
      })
    }

    // Finalmente calculamos el contenido del editor y notificamos de cambios.
    const deltaFinal = event.editor.getContents()
    let editorContentString = ''
    deltaFinal.ops.forEach((op) => {
      if (typeof op.insert === 'string') {
        // Se trata de un string asi que no hay tanto problema con esto
        editorContentString += op.insert
      } else {
        if (op.insert === undefined) return
        // Veremos si se trata de un Embed que nosotros hicimos...
        const objKeys = Object.keys(op.insert)
        if (objKeys.includes('format-placeholder')) {
          // Intentar obtener el placeholder...
          const formatPlaceholder = op.insert[
            'format-placeholder'
          ] as FormatPlaceholderData
          // Agregar el placeholder al string
          const converter = this.replaceablePlaceholders.find(
            (rp) => rp.type === formatPlaceholder.type
          )
          if (converter === undefined) {
            console.warn(
              'Unknown editor converter for QuillJS placeholder type: ' +
                formatPlaceholder.type
            )
            editorContentString += formatPlaceholder.real_value
          } else {
            editorContentString += converter.embedSerialize(formatPlaceholder)
          }
        }
      }
    })

    if (editorContentString.endsWith('\n')) {
      const lastIndex = editorContentString.lastIndexOf('\n')
      editorContentString = editorContentString.substring(0, lastIndex)
    }

    editorContentString = editorContentString.replace(/\uFEFF/g, '')

    // No llamar a valueChange si el contenido calculado es igual al anterior...
    if (this.value === editorContentString) return

    this.changedByAPI = true
    this.valueChange.emit(editorContentString)
  }

  handleHeaderButtonClick(level: number) {
    if (!this.editor) return

    let selection = this.editor.getSelection()
    if (selection === null) {
      this.editor.focus()
      selection = this.editor.getSelection()
      if (selection === null) {
        return
      }
    }

    const [line, offset] = this.editor.getLine(selection.index)
    if (line === null) return
    const lineStart = selection.index - offset
    const lineOps = line.delta().ops

    if (lineOps.length > 0) {
      //console.log('Line has ops, size: ' + lineOps.length)
      const firstLineOp = lineOps[0]
      if (typeof firstLineOp.insert === 'string') {
        //console.log('firstLineOp insert is of type string')
        let lineHeaderLevel = 0
        let lineHasSpace = false

        if (
          firstLineOp.insert === '###' ||
          firstLineOp.insert.startsWith('### ')
        ) {
          lineHeaderLevel = 3
          if (firstLineOp.insert.startsWith('### ')) {
            lineHasSpace = true
          }
        } else if (
          firstLineOp.insert === '##' ||
          firstLineOp.insert.startsWith('## ')
        ) {
          lineHeaderLevel = 2
          if (firstLineOp.insert.startsWith('## ')) {
            lineHasSpace = true
          }
        } else if (
          firstLineOp.insert === '#' ||
          firstLineOp.insert.startsWith('# ')
        ) {
          lineHeaderLevel = 1
          if (firstLineOp.insert.startsWith('# ')) {
            lineHasSpace = true
          }
        }

        //console.log('Determined lineHeaderLevel: ' + lineHeaderLevel)

        if (lineHeaderLevel === level) {
          //console.log(
          //  'lineHeaderLevel matches level, deleting range from: [' +
          //    lineStart +
          //    '...' +
          //    level +
          //    ']'
          //)
          // Quitar formato de la línea...
          this.editor.deleteText(
            new Range(lineStart, level + (lineHasSpace ? 1 : 0))
          )
          //console.log(
          //  'Updating user selection to: [' +
          //    (selection.index - level) +
          //    '...' +
          //    selection.length +
          //    ']'
          //)
          this.editor.setSelection(
            new Range(
              selection.index - level - (lineHasSpace ? 1 : 0),
              selection.length
            )
          )
        } else {
          // Quitar formato de la línea anterior...
          this.editor.deleteText(
            new Range(lineStart, lineHeaderLevel + (lineHasSpace ? 1 : 0))
          )
          // Agregar formato de la línea nueva
          if (level === 1) {
            this.editor.insertText(lineStart, '# ', 'user')
          }
          if (level === 2) {
            this.editor.insertText(lineStart, '## ', 'user')
          }
          if (level === 3) {
            this.editor.insertText(lineStart, '### ', 'user')
          }
          this.editor.setSelection(
            new Range(
              selection.index -
                lineHeaderLevel -
                (lineHasSpace ? 1 : 0) +
                level +
                1,
              selection.length
            )
          )
        }
      } else {
        // Agregar formato de la línea nueva
        if (level === 1) {
          this.editor.insertText(lineStart, '# ', 'user')
        }
        if (level === 2) {
          this.editor.insertText(lineStart, '## ', 'user')
        }
        if (level === 3) {
          this.editor.insertText(lineStart, '### ', 'user')
        }
        this.editor.setSelection(
          new Range(selection.index + level + 1, selection.length)
        )
      }
    } else {
      // Agregar formato de la línea nueva
      if (level === 1) {
        this.editor.insertText(lineStart, '# ', 'user')
      }
      if (level === 2) {
        this.editor.insertText(lineStart, '## ', 'user')
      }
      if (level === 3) {
        this.editor.insertText(lineStart, '### ', 'user')
      }
      this.editor.setSelection(
        new Range(selection.index + level + 1, selection.length)
      )
    }
  }

  handleBoldButtonClick() {
    if (!this.editor) return

    let selection = this.editor.getSelection()
    if (selection === null) {
      this.editor.focus()
      selection = this.editor.getSelection()
      if (selection === null) {
        return
      }
    }

    this.editor.format('bold', true)

    this.editor.insertEmbed(selection.index, '**', 'user')
    this.editor.insertText(selection.index + selection.length + 2, '**', 'user')
    this.editor.setSelection(
      new Range(selection.index + 2, selection.length),
      'user'
    )
  }

  handleItalicButtonClick() {
    if (!this.editor) return

    let selection = this.editor.getSelection()
    if (selection === null) {
      this.editor.focus()
      selection = this.editor.getSelection()
      if (selection === null) {
        return
      }
    }

    this.editor.insertEmbed(
      selection.index,
      'format-anchor-italic',
      '__',
      'user'
    )

    const hasSelection = selection.length !== 0
    if (!hasSelection) {
      this.editor.insertText(selection.index + 1, '<text>', 'user')
    }

    this.editor.insertEmbed(
      selection.index + 1 + (!hasSelection ? 6 : selection.length),
      'format-anchor-italic',
      '__',
      'user'
    )

    this.editor.setSelection(
      new Range(selection.index + 1, !hasSelection ? 6 : selection.length),
      'user'
    )
    this.editor.format('italic', true)
  }

  handleStrikeButtonClick() {
    if (!this.editor) return

    let selection = this.editor.getSelection()
    if (selection === null) {
      this.editor.focus()
      selection = this.editor.getSelection()
      if (selection === null) {
        return
      }
    }

    this.editor.insertText(selection.index, '~~', 'user')
    this.editor.insertText(selection.index + selection.length + 2, '~~', 'user')
    this.editor.setSelection(
      new Range(selection.index + 2, selection.length),
      'user'
    )
  }

  getRef() {
    return this.ref
  }

  insert(text: string) {
    if (!this.editor) return

    let selection = this.editor.getSelection()
    if (selection === null) {
      this.editor.focus()
      selection = this.editor.getSelection()
      if (selection === null) {
        return
      }
    }

    this.editor.insertText(selection.index, text, 'user')
  }

  insertEmbed(embed: FormatPlaceholderData) {
    if (!this.editor) return

    let selection = this.editor.getSelection()
    if (selection === null) {
      this.editor.focus()
      selection = this.editor.getSelection()
      if (selection === null) {
        return
      }
    }

    this.editor.deleteText(
      {
        index: selection.index,
        length: selection.length
      },
      'user'
    )

    this.editor.insertEmbed(
      selection.index,
      'format-placeholder',
      embed,
      'user'
    )

    this.editor.insertText(selection.index + 1, ' ', 'user')

    selection.index = selection.index + 2
    selection.length = 0

    this.editor.setSelection(selection)
  }

  onEditorFocus() {
    this.classFocused = true
  }

  onEditorBlur() {
    this.classFocused = false
  }

  static instances: EditorComponent[] = []
  static getEditorInstanceByTarget(target: EventTarget | HTMLElement) {
    for (const instance of EditorComponent.instances) {
      if (
        (instance.getRef().nativeElement as HTMLElement).contains(
          target as HTMLElement
        )
      ) {
        return instance
      }
    }

    return null
  }

  markAsOwnedByEmojiSelector() {
    this.ownedByEmojiSelector = true
  }

  unmarkAsOwnedByEmojiSelector() {
    this.ownedByEmojiSelector = false
  }

  handleCopiedPlaceholder(
    qjType: ReplaceablePlaceholderType,
    qjValue: string,
    qjRealValue: string,
    qjCustomEmojiId: string | null,
    qjCustomEmojiAnimated: string | null
  ): FormatPlaceholderData | undefined {
    if (qjType === 'dc-channel') {
      const channel = this.dashboard
        .getActiveServerChannels()
        .find((c) => c.id === qjRealValue)
      if (channel === undefined) {
        return {
          type: qjType,
          value: '<#' + qjRealValue + '>',
          real_value: qjRealValue,
          customEmojiId: qjCustomEmojiId ?? undefined,
          customEmojiAnimated:
            qjCustomEmojiAnimated === null
              ? false
              : qjCustomEmojiAnimated === 'true'
        }
      } else {
        return {
          type: qjType,
          value: '#' + channel.name,
          real_value: channel.id,
          customEmojiId: qjCustomEmojiId ?? undefined,
          customEmojiAnimated:
            qjCustomEmojiAnimated === null
              ? false
              : qjCustomEmojiAnimated === 'true'
        }
      }
    } else if (qjType === 'dc-role-mention') {
      const role = this.dashboard
        .getActiveServerRoles()
        .find((r) => r.id === qjRealValue)
      if (role === undefined) {
        return {
          type: qjType,
          value: '<@&' + qjRealValue + '>',
          real_value: qjRealValue,
          customEmojiId: qjCustomEmojiId ?? undefined,
          customEmojiAnimated:
            qjCustomEmojiAnimated === null
              ? false
              : qjCustomEmojiAnimated === 'true'
        }
      } else {
        return {
          type: qjType,
          value: '@' + role.name,
          real_value: role.id,
          customEmojiId: qjCustomEmojiId ?? undefined,
          customEmojiAnimated:
            qjCustomEmojiAnimated === null
              ? false
              : qjCustomEmojiAnimated === 'true'
        }
      }
    } else if (qjType === 'placeholder') {
      const placeholder = this.availablePlaceholders.find(
        (p) => p.name === qjRealValue
      )
      if (placeholder === undefined) {
        return undefined
      } else {
        return {
          type: qjType,
          value: placeholder.name,
          real_value: placeholder.name,
          customEmojiId: qjCustomEmojiId ?? undefined,
          customEmojiAnimated:
            qjCustomEmojiAnimated === null
              ? false
              : qjCustomEmojiAnimated === 'true'
        }
      }
    } else if (qjType === 'dc-custom-emoji') {
      const emoji = this.dashboard
        .getActiveServerEmojis()
        .find((e) => e.id === qjRealValue)
      if (emoji === undefined) {
        return undefined
      } else {
        return {
          type: qjType,
          value: qjValue,
          real_value: qjRealValue,
          customEmojiId: qjCustomEmojiId ?? undefined,
          customEmojiAnimated:
            qjCustomEmojiAnimated === null
              ? false
              : qjCustomEmojiAnimated === 'true'
        }
      }
    } else if (qjType === 'dc-emoji') {
      if (qjRealValue.startsWith(':') && qjRealValue.endsWith(':')) {
        const emoji = retrieveEmojiUnicodeByShortName(qjRealValue)
        if (emoji !== null) {
          return {
            type: qjType,
            value: qjValue,
            real_value: qjRealValue,
            customEmojiId: qjCustomEmojiId ?? undefined,
            customEmojiAnimated:
              qjCustomEmojiAnimated === null
                ? false
                : qjCustomEmojiAnimated === 'true'
          }
        }
      } else {
        const parsedEmojis = parse(qjRealValue)
        if (parsedEmojis.length > 0) {
          return {
            type: qjType,
            value: qjValue,
            real_value: parsedEmojis[0].text,
            customEmojiId: qjCustomEmojiId ?? undefined,
            customEmojiAnimated:
              qjCustomEmojiAnimated === null
                ? false
                : qjCustomEmojiAnimated === 'true'
          }
        }
      }
    }

    return undefined
  }
}

export interface PlaceholderOption {
  name: string
  description: string
}

export interface PlaceholderOptionPreview {
  name: string
  preview: string
}
